import { FC, useMemo } from 'react';

import { IB2CArticle } from '@app/native/src/interfaces/article';

import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { PAGES } from '@lib/tools/views/urls';

import BannerCard, { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import ProductTags from '@components/web/src/components/Catalog/ProductTags/ProductTags';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ArticleCard from '@components/web/src/organisms/Cards/ArticleCard/ArticleCard';

interface IProps {
  articleData: IB2CArticle;
  articlesSwiperData: IB2CArticle[];
  banner?: TBannerCard;
  previousPage: string;
  handleBackBtnClick: (page: string) => void;
  handleBannerClick?: (route?: string, linkParams?: string) => void;
  navigateToArticlePage: (articleId: string) => void;
}

const ArticlePage: FC<IProps> = ({
  articleData,
  articlesSwiperData,
  banner,
  previousPage = '',
  handleBackBtnClick,
  handleBannerClick,
  navigateToArticlePage,
}) => {
  const {
    image,
    title,
    article_type: { name: articleType },
    data: content,
    tags,
    author: { image: authorImage, name: authorName },
  } = articleData;

  const { articlePage } = localeApp;

  const backButtonData = useMemo(() => {
    const isPreviousPageHome = previousPage?.includes(PAGES.vinhood.home);

    return {
      btnText: isPreviousPageHome
        ? articlePage.backButtonToHomePageText
        : articlePage.backButtonToArticleCatalogPageText,
      page: isPreviousPageHome ? PAGES.vinhood.home : PAGES.vinhood.articles,
    };
  }, [previousPage]);

  return (
    <div className="article-page-container">
      <div className="top-section">
        <BackButton handleClick={() => handleBackBtnClick(backButtonData.page)} />
        <div className="image-container">
          <img alt={title} src={image} />
        </div>
      </div>
      <div className="content-section">
        <p className="article-type">{articleType}</p>
        <p className="article-title">
          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            size="h5"
            text={title.toLowerCase()}
            weight="semibold"
          />
        </p>
        <div className="article-content">
          <p>
            <LocaleFragment message={content} />
          </p>
        </div>
        <div className="tags-container">{tags.length > 0 && <ProductTags tags={tags} />}</div>
        <div className="author-container">
          <div className="avatar-container">
            <img alt={authorName} src={authorImage} />
          </div>
          <p>{authorName}</p>
        </div>
      </div>
      {articlesSwiperData.length > 0 && (
        <div className="articles-swiper">
          <SwiperHOC title={articlePage.interestedInText}>
            {articlesSwiperData.map((article, index) => (
              <ArticleCard key={index} articleData={article} navigateToArticlePage={navigateToArticlePage} />
            ))}
          </SwiperHOC>
        </div>
      )}
      {!!banner && (
        <div className="articles-banner">
          <BannerCard {...banner} onClickHandler={handleBannerClick} />
        </div>
      )}
    </div>
  );
};

export default ArticlePage;
