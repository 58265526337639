import { styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const RedirectLink = styled(Flexbox)`
  cursor: pointer;
`;

export const Icon = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
`;
