import { styled } from 'styled-components';

import { SCREEN_SIZE, STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const TastePathProgressContainer = styled(Flexbox)<{ $stepsQuantity: number }>`
  position: relative;
  padding-bottom: 8px;
  gap: ${({ $stepsQuantity }) => {
    return (
      {
        3: '50px',
        4: '30px',
        5: '20px',
      }[$stepsQuantity] || '50px'
    );
  }};

  @media screen and (min-width: ${SCREEN_SIZE.vhApp}) {
    gap: 0;
  }

  &::after {
    position: absolute;
    z-index: 1;
    bottom: 13px;
    left: 0;
    width: 100%;
    min-height: 2px;
    content: '';
    background-color: ${STATIC_COLORS.warmGray[500]};
  }
`;

export const StepItem = styled.div<{ $isActive: boolean; $isDone: boolean }>`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ $isActive, $isDone }) =>
    $isActive || $isDone ? STATIC_COLORS.base.green : STATIC_COLORS.warmGray[500]};

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    content: '';
    background-color: ${({ $isActive, $isDone }) => {
      if ($isDone) {
        return STATIC_COLORS.base.green;
      }
      if ($isActive) {
        return STATIC_COLORS.base.white;
      }
      return STATIC_COLORS.warmGray[500];
    }};
  }
`;

export const StepItemsWrapper = styled(Flexbox)<{ $stepsQuantity: number }>`
  gap: ${({ $stepsQuantity }) => {
    return (
      {
        3: '22.5px',
        4: '13.6px',
        5: '8.3px',
      }[$stepsQuantity] || '22.5px'
    );
  }};
  justify-content: center;
`;

export const StepItemsGroup = styled(Flexbox)<{ $stepsQuantity: number }>`
  width: ${({ $stepsQuantity }) => {
    return (
      {
        3: '81px',
        4: '63.25px',
        5: '52.6px',
      }[$stepsQuantity] || '81px'
    );
  }};

  span {
    height: 100%;
    text-align: center;
  }
`;
