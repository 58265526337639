import { Dispatch, FC, SetStateAction } from 'react';

import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { parseFindProduct } from '@lib/core/products/utils';
import { IIdentityCardProps } from '@lib/core/products/utils/parseProductIdentities';
import { TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PRODUCT_CATEGORY_COFFEE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import ChefRecommendsCard, { IRecipe } from '@components/web/src/organisms/Cards/ChefRecommendsCard/ChefRecommendsCard';
import EssenceCard, { IEssenceProps } from '@components/web/src/organisms/Cards/EssenceCard/EssenceCard';
import IdentityCard from '@components/web/src/organisms/Cards/IdentityCard/IdentityCard';
import PreparationCard, { IPreparationItem } from '@components/web/src/organisms/Cards/PreparationCard/PreparationCard';
import ReadMoreCard, { IReadMoreCardProps } from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';
import ProductDetails from '@components/web/src/organisms/ProductDetails/Web/ProductDetails';
import FindProductPopup from '@components/web/src/shared/FindProductPopup/FindProductPopup';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import * as S from '@components/web/src/templates/ProductPage/Web/styles';

interface IProps {
  locale: string;
  productInstanceData: TProductInstance;
  productCategory: TProductCategory;
  perfectForData: string;
  recipesData: IRecipe[];
  essencesData: IEssenceProps[];
  preparationsData: IPreparationItem[];
  productIdentitiesData: IIdentityCardProps;
  sustainabilityData: IReadMoreCardProps[];
  isShowPromoLabel: boolean;
  isEnableLocationMapAddon: boolean;
  isShowProductLocationAddon: boolean;
  isEnableVusionAddon: boolean;
  shouldHideWishlist: boolean;
  shouldHideComment: boolean;
  shouldHideFeedback: boolean;
  isWishlistProductListLoading?: boolean;
  isProductInstanceInWishlist: boolean;
  isProductPDFDownload?: boolean;
  feedback?: TProductFeedbackValue;
  discoveryQuiz: IDiscoveryQuiz;
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching?: boolean;
  isUserHasAnyCharacter?: boolean;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
  navigateToQuizSelectionPage: () => void;
  handleUpdateFeedback?: (feedback: TProductFeedbackValue, productId: string, productName?: string) => void;
  handleUpdateWishlistProductList?: (productId: string) => void;
  onBackButtonClick: () => void;
  navigateToCatalog: () => void;
}

const ProductPage: FC<IProps> = ({
  locale,
  productInstanceData,
  productCategory,
  perfectForData,
  recipesData,
  essencesData,
  productIdentitiesData,
  preparationsData,
  sustainabilityData,
  isShowPromoLabel,
  isEnableLocationMapAddon,
  isShowProductLocationAddon,
  isEnableVusionAddon,
  isProductInstanceInWishlist,
  isWishlistProductListLoading,
  isProductPDFDownload,
  feedback,
  shouldHideComment,
  shouldHideFeedback,
  shouldHideWishlist,
  discoveryQuiz,
  isUserHasAnyCharacter,
  isCommentsLoaded,
  commentsList,
  isNewCommentsDataFetching,
  navigateToQuizSelectionPage,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
  onBackButtonClick,
  navigateToCatalog,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
}) => {
  const {
    productPage: {
      perfectForTitle,
      preparationsTitle,
      identityCardTitle,
      identityCardSubtitle,
      seeSimilarBtn,
      chefRecommendsTitle,
      chefRecommendsSubtitle,
      essenceCardSubtitle,
      essenceCardTitle,
      sustainabilityTitle,
      sustainabilitySubtitle,
    },
    productDetails: { findItOnShelf },
    productCategoriesPlural,
    productCategories,
  } = localeCommon;
  const { productIdentities, identityWarningText } = productIdentitiesData;

  const { publishedTerms } = LocaleUtils;
  const productCategoriesText = LocaleUtils.publishedTerms[productCategories[productCategory].id];
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory].id];

  const { isFindProductButtonEnable, productLocationDescription, productLocationMap, productEcommerceId } =
    parseFindProduct({
      isEnableLocationMapAddon,
      isEnableVusionAddon,
      isShowProductLocationAddon,
      productInstanceData,
    });

  return (
    <S.WebProductPageContainer>
      <S.StyledBackButton handleClick={onBackButtonClick} />
      <ProductDetails
        commentsList={commentsList}
        discoveryQuiz={discoveryQuiz}
        feedback={feedback}
        handleSubmitComment={handleSubmitComment}
        handleUpdateFeedback={handleUpdateFeedback}
        handleUpdateWishlistProductList={handleUpdateWishlistProductList}
        isCommentsLoaded={isCommentsLoaded}
        isEnableLocationMapAddon={isEnableLocationMapAddon}
        isEnableVusionAddon={isEnableVusionAddon}
        isNewCommentsDataFetching={isNewCommentsDataFetching}
        isProductInstanceInWishlist={isProductInstanceInWishlist}
        isProductPDFDownload={isProductPDFDownload}
        isShowProductLocationAddon={isShowProductLocationAddon}
        isShowPromoLabel={isShowPromoLabel}
        isUserHasAnyCharacter={isUserHasAnyCharacter}
        isWishlistProductListLoading={isWishlistProductListLoading}
        locale={locale}
        navigateToQuizSelectionPage={navigateToQuizSelectionPage}
        productInstanceData={productInstanceData}
        setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
        shouldHideComment={shouldHideComment}
        shouldHideFeedback={shouldHideFeedback}
        shouldHideWishlist={shouldHideWishlist}
      />
      <S.ContentWrapper direction="column-reverse" gap={32} padding="32px 16px 48px">
        <Flexbox isFullWidth direction="column" gap={32}>
          {perfectForData && (
            <Flexbox direction="column" gap={0}>
              <S.Title
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={perfectForTitle}
                weight="semibold"
              />
              <S.PerfectForWrapper>
                <Text color={STATIC_COLORS.base.black} size="subtitle2" text={perfectForData} />
              </S.PerfectForWrapper>
            </Flexbox>
          )}
          {productCategory === PRODUCT_CATEGORY_COFFEE && (
            <S.PreparationsWrapper>
              <S.Title
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={preparationsTitle}
                weight="semibold"
              />
              <SwiperHOC spaceBetween={13}>
                {preparationsData?.map((preparationsItem, idx) => <PreparationCard key={idx} {...preparationsItem} />)}
              </SwiperHOC>
            </S.PreparationsWrapper>
          )}
          <Flexbox direction="column" gap={0}>
            <S.Title
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="h5"
              text={identityCardTitle}
              weight="semibold"
            />
            <S.Subtitle
              color={STATIC_COLORS.base.black}
              size="body2"
              text={identityCardSubtitle}
              localeOptions={{
                productCategoriesText,
                productCategory,
              }}
            />
            <IdentityCard identityWarningText={identityWarningText} productIdentities={productIdentities} />
          </Flexbox>
          {isFindProductButtonEnable && (
            <FindProductPopup
              isEnableLocationMapAddon={isEnableLocationMapAddon}
              isEnableVusionAddon={isEnableVusionAddon}
              productEcommerceId={productEcommerceId}
              productLocationDescription={productLocationDescription}
              productLocationMap={productLocationMap}
            >
              <Button
                size="sm"
                text={findItOnShelf}
                type="button"
                variant={VH_VARIANTS.PRIMARY}
                onClick={() =>
                  MixpanelTracker.events.findMe(
                    productInstanceData,
                    isProductInstanceInWishlist,
                    null,
                    MP_POSITION_CONTEXT.PRODUCT_PAGE,
                  )
                }
              />
            </FindProductPopup>
          )}
          <Button
            iconVariant="stack"
            localeOptions={{ productCategory, productCategoryPluralText }}
            size="sm"
            text={seeSimilarBtn}
            variant={VH_VARIANTS.LIGHT}
            handleClick={() => {
              MixpanelTracker.events.seeSimilarProductsClick(productInstanceData);
              navigateToCatalog();
            }}
          />
        </Flexbox>
        <Flexbox isFullWidth direction="column" gap={32}>
          {!!recipesData.length && (
            <Flexbox direction="column" gap={0}>
              <S.Title
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={chefRecommendsTitle}
                weight="semibold"
              />
              <S.Subtitle
                color={STATIC_COLORS.base.black}
                size="body2"
                text={chefRecommendsSubtitle}
                localeOptions={{
                  productCategoriesText,
                  productCategory,
                }}
              />
              <SwiperHOC spaceBetween={13}>
                {recipesData.map((recipeItem, idx) => (
                  <ChefRecommendsCard key={idx} {...recipeItem} />
                ))}
              </SwiperHOC>
            </Flexbox>
          )}
          {!!essencesData.length && (
            <Flexbox direction="column" gap={0}>
              <S.Title
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={essenceCardTitle}
                weight="semibold"
                localeOptions={{
                  productCategoriesText,
                  productCategory,
                }}
              />
              <S.Subtitle color={STATIC_COLORS.base.black} size="body2" text={essenceCardSubtitle} />
              <SwiperHOC spaceBetween={13}>
                {essencesData.map(essence => (
                  <EssenceCard key={essence.identifier} productCategory={productCategory} {...essence} />
                ))}
              </SwiperHOC>
            </Flexbox>
          )}
          {!!sustainabilityData.length && (
            <Flexbox direction="column" gap={0}>
              <S.Title
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={sustainabilityTitle}
                weight="semibold"
              />
              <S.Subtitle
                color={STATIC_COLORS.base.black}
                size="body2"
                text={sustainabilitySubtitle}
                localeOptions={{
                  productCategoriesText,
                  productCategory,
                }}
              />
              <SwiperHOC spaceBetween={13}>
                {sustainabilityData.map((sustainabilityItem, idx) => {
                  return (
                    <ReadMoreCard
                      key={idx}
                      cardTitle={sustainabilityItem?.cardTitle}
                      description={sustainabilityItem?.description}
                      minimumCharacters={120}
                    />
                  );
                })}
              </SwiperHOC>
            </Flexbox>
          )}
        </Flexbox>
      </S.ContentWrapper>
    </S.WebProductPageContainer>
  );
};

export default ProductPage;
