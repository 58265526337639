import styled from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { DEFAULT_COLOR_THEME } from '@components/web/src/foundations';

export const AuthPageContainer = styled.div`
  width: 100%;
  flex: 1;
  background-color: ${({ theme }) =>
    theme?.colors?.secondaryColor['-100'] || DEFAULT_COLOR_THEME.secondaryColor['-100']};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px 16px 32px;

  .auth-form-server-error {
    margin-top: 8px;
  }
`;

export const StyledBackButton = styled(BackButton)`
  width: auto;
  padding: 8px 16px;
`;
