import { FC } from 'react';
import { NavigateFunction } from 'react-router-dom';

import { TProductCategory } from '@lib/core/products/types';
import { prependBasename } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
  SITUATIONAL_PRESELECTED_PRODUCT_CATEGORY_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import { ReactComponent as WhiteRightIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_white_right_arrow.svg';
import beerSituationalCardImage from '@components/web/src/assets/legacy/b2c/images/beer_character_situational_image.png';
import coffeeSituationalCardImage from '@components/web/src/assets/legacy/b2c/images/coffee_character_situational_image.png';
import wineSituationalCardImage from '@components/web/src/assets/legacy/b2c/images/wine_character_situational_image.png';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/SituationalCard/styles';

interface IProps {
  productCategory: TProductCategory;
  navigate: NavigateFunction;
}

const SituationalCard: FC<IProps> = ({ productCategory, navigate }) => {
  const { productCategories } = localeCommon;

  const image = {
    [PRODUCT_CATEGORY_BEER]: beerSituationalCardImage,
    [PRODUCT_CATEGORY_COFFEE]: coffeeSituationalCardImage,
    [PRODUCT_CATEGORY_WINE]: wineSituationalCardImage,
  };

  const handleClick = () =>
    navigate(
      prependBasename(PAGES.vinhood.quiz.situational, {
        [SITUATIONAL_PRESELECTED_PRODUCT_CATEGORY_URL_PARAM]: productCategory,
      }),
    );

  return (
    <S.SituationalCardContainer aria-hidden $productCategory={productCategory} onClick={handleClick}>
      <S.ImageContainer>
        <img alt="products" src={image[productCategory]} />
      </S.ImageContainer>
      <S.ContentContainer>
        <Text
          color={STATIC_COLORS.base.white}
          size="body1"
          text={productCategories[productCategory]}
          textTransform="capitalize"
          weight="semibold"
        />
        <WhiteRightIcon />
      </S.ContentContainer>
    </S.SituationalCardContainer>
  );
};

export default SituationalCard;
