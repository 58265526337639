import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCommentsHandler } from '@lib/core/comments/hooks/useCommentsHandler';
import { fetchExperiencesLists } from '@lib/core/experiences/slices/experienceCatalogSlice';
import { TProductInstance } from '@lib/core/products/types';
import { getLimitedProductsListData, getProductsListData } from '@lib/core/products/utils';
import { useRetailer, useRetailerLocation } from '@lib/core/retailers/hooks';
import { retailerLocationDataApiUrlCreator } from '@lib/core/retailers/slices/urls';
import { ASSOCIATED_RETAILER_SLUG_QUERY, GPRL_CHARACTER_QUERY } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import request from '@lib/core/service/requests/request';
import { getMultipleUniqueRandomItemsFromArray, prependBasename } from '@lib/core/service/utils';
import { useLocationList, useProductFeedback, useProductList, useUser } from '@lib/core/users/hooks';
import { isLocationInWishlistFilter } from '@lib/core/users/utils/filters';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { parseExploreLocation } from '@lib/tools/explore/utils/parseExploreLocation';
import {
  LIMITED_CATALOG,
  PRODUCT_CATEGORY_QUERY,
  RETAILER_LOCATION_ID_URL_PARAM,
  RETAILER_SLUG_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { IMapLocation } from '@components/web/src/organisms/Cards/MapCard/MapCard';
import { ExplorePage } from '@components/web/src/templates/ExplorePage/ExplorePage';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

export const ExploreContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [exploreLocationData, setExploreLocationData] = useState(null);
  const [isExploreLocationDataLoading, setIstExploreLocationDataLoading] = useState(false);

  const { productCategory, locale } = useApp();
  const { retailerSlug: defaultRetailerSlug, isRetailerLoading } = useRetailer();
  const { retailerLocationId: defaultRetailerLocationId } = useRetailerLocation();
  const {
    isUserAuthenticated,
    userBestMatchCharacterForCurrentServiceProductCategory,
    isUserDataLoading,
    isUserHasAnyCharacter,
  } = useUser();
  const { discoveryQuiz } = useDiscoveryQuizData();
  const { wishlistProductInstanceIds, isWishlistProductListLoading, handleUpdateWishlistProductList } =
    useProductList();
  const { feedbackData, handleUpdateFeedback } = useProductFeedback();
  const { locationList, isLocationListLoading, handleUpdateLocationList } = useLocationList();

  const retailerLocationIdUrlParam = searchParams.get(RETAILER_LOCATION_ID_URL_PARAM);
  const retailerSlugUrlParam = searchParams.get(RETAILER_SLUG_URL_PARAM);

  const retailerLocationId = retailerLocationIdUrlParam || defaultRetailerLocationId;
  const retailerSlug = retailerSlugUrlParam || defaultRetailerSlug;

  const isCurrentRetailer =
    (!retailerLocationIdUrlParam && !retailerSlugUrlParam) || retailerLocationIdUrlParam === defaultRetailerLocationId;

  const { locationData, producerData } = parseExploreLocation(exploreLocationData);
  const { producerId, producerLogo, producerWebsite } = producerData;
  const {
    associatedRetailerLocation,
    locationAddress,
    locationCatalogType,
    locationCoordinates,
    locationDescription,
    locationEmail,
    locationId,
    locationImages,
    locationName,
    locationPhone,
    locationStoreType,
    locationTasteMatch,
    locationSlug,
  } = locationData;

  const mapLocationList: IMapLocation[] = useMemo(
    () =>
      [
        locationCoordinates && {
          address: locationAddress,
          coordinates: locationCoordinates,
          isCurrentLocation: true,
        },
      ].filter(Boolean),
    [locationCoordinates],
  );

  const exploreLocationTasteMatchValue =
    locationTasteMatch && typeof locationTasteMatch[productCategory] === 'number'
      ? locationTasteMatch[productCategory]
      : null;

  const {
    experiencesData: { results: experienceData },
    isExperienceRequestLoading,
  } = useTypedSelector(state => state.experienceCatalog);

  const randomLimitedExperienceData = useMemo(
    () => getMultipleUniqueRandomItemsFromArray(experienceData, 3),
    [experienceData],
  );

  const randomExploreLocationImages = useMemo(
    () => getMultipleUniqueRandomItemsFromArray(locationImages, locationImages?.length),
    [locationImages],
  );

  const [productInstanceData, setProductInstanceData] = useState<TProductInstance | null>(null);
  const [isProductInstanceDataLoading, setIsProductInstanceDataLoading] = useState(false);

  useEffect(() => {
    if (exploreLocationData) {
      MixpanelTracker.events.exploreSectionView({
        locationId,
        locationName: locationName || locationSlug || '',
        locationSlug,
        tasteMatchLevel: exploreLocationTasteMatchValue,
      });
    }
  }, [exploreLocationData]);

  useEffect(() => {
    if (!exploreLocationData && !isExploreLocationDataLoading) {
      const getExploreLocationData = async () => {
        setIstExploreLocationDataLoading(true);
        const response = await request(retailerLocationDataApiUrlCreator({ retailerLocationId, retailerSlug }));

        setExploreLocationData(response);
        setIstExploreLocationDataLoading(false);
      };

      if (!exploreLocationData) {
        getExploreLocationData();
      }
    }
  }, [exploreLocationData]);

  useEffect(() => {
    const fetchProductData = async () => {
      if (productInstanceData) return;

      setIsProductInstanceDataLoading(true);

      const isLimitedCatalogType = locationCatalogType === LIMITED_CATALOG;

      const params = {
        [GPRL_CHARACTER_QUERY]: userBestMatchCharacterForCurrentServiceProductCategory?.identifier,
        [PRODUCT_CATEGORY_QUERY]: productCategory,
        ...(isLimitedCatalogType && { [ASSOCIATED_RETAILER_SLUG_QUERY]: associatedRetailerLocation?.slug }),
        limit: 1,
        offset: 0,
      };

      if (isLimitedCatalogType) {
        const res = await getLimitedProductsListData(params);
        const productData = res.first_priority?.[0] || res.second_priority?.[0] || res.third_priority?.[0];
        setProductInstanceData(productData);
      } else {
        const res = await getProductsListData(params);
        const productData = res.results[0];
        setProductInstanceData(productData);
      }

      setIsProductInstanceDataLoading(false);
    };

    if (isCurrentRetailer) {
      fetchProductData();
    }
  }, [userBestMatchCharacterForCurrentServiceProductCategory]);

  useEffect(() => {
    if (!isExperienceRequestLoading && producerId && isCurrentRetailer) {
      dispatch(fetchExperiencesLists({ producer: producerId, productCategory }));
    }
  }, [productCategory, producerId]);

  const isLoading =
    isProductInstanceDataLoading ||
    isUserDataLoading ||
    isExperienceRequestLoading ||
    isExploreLocationDataLoading ||
    isRetailerLoading;

  const navigateToCatalogPage = () => navigate(prependBasename(PAGES.vinhood.catalog));

  const navigateToQuizSelectionPage = () => navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));

  const navigateToExploreListPage = () => navigate(prependBasename(PAGES.vinhood.exploreList));

  const {
    commentsList,
    handleSubmitComment,
    isCommentsLoaded,
    isNewCommentsDataFetching,
    setIsNewCommentsDataFetching,
  } = useCommentsHandler();

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ExplorePage
          commentsList={commentsList}
          discoveryQuiz={discoveryQuiz}
          experienceData={randomLimitedExperienceData}
          feedbackData={feedbackData}
          handleSubmitComment={handleSubmitComment}
          handleUpdateFeedback={handleUpdateFeedback}
          handleUpdateLocationList={handleUpdateLocationList}
          handleUpdateWishlistProductList={handleUpdateWishlistProductList}
          isCommentsLoaded={isCommentsLoaded}
          isCompactVariant={!isCurrentRetailer}
          isLocationInWishlist={isLocationInWishlistFilter(locationList, locationId)}
          isLocationListLoading={isLocationListLoading}
          isNewCommentsDataFetching={isNewCommentsDataFetching}
          isUserAuthenticated={isUserAuthenticated}
          isUserHasAnyCharacter={isUserHasAnyCharacter}
          isWishlistProductListLoading={isWishlistProductListLoading}
          locale={locale}
          mapLocationList={mapLocationList}
          navigateToCatalogPage={navigateToCatalogPage}
          navigateToExploreListPage={navigateToExploreListPage}
          navigateToQuizSelectionPage={navigateToQuizSelectionPage}
          productCategory={productCategory}
          productInstanceData={productInstanceData}
          retailerLocationAddress={locationAddress}
          retailerLocationDescription={locationDescription}
          retailerLocationEmail={locationEmail}
          retailerLocationId={locationId}
          retailerLocationImages={randomExploreLocationImages}
          retailerLocationLogo={producerLogo}
          retailerLocationName={locationName}
          retailerLocationPhone={locationPhone}
          retailerLocationSlug={locationSlug}
          retailerLocationTasteMatchValue={exploreLocationTasteMatchValue}
          retailerLocationWebsite={producerWebsite}
          setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
          storeType={locationStoreType}
          wishlistProductInstanceIds={wishlistProductInstanceIds}
        />
      )}
    </>
  );
};
