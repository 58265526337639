import { FC, useState } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { TLanguage } from '@lib/core/retailers/types';
import { isAppInIframe } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PRODUCT_CATEGORY_WINE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { CharacterScroller } from '@components/web/src/organisms/CharacterScroller/CharacterScroller';
import LanguageSwitcher from '@components/web/src/organisms/LanguageSwitcher/LanguageSwitcher';
import LogoutModal from '@components/web/src/templates/Modals/LogoutModal/LogoutModal';
import * as S from '@components/web/src/templates/Start&HomePages/Widget/HomePage/styles';

export const HOME_PAGE_VARIANTS = {
  ANON: 'anon',
  ANON_WITH_CHARACTER: 'anonWithCharacter',
  LOGGED: 'logged',
  LOGGED_WITH_CHARACTER: 'loggedWithCharacter',
} as const;

export type THomePageVariants = (typeof HOME_PAGE_VARIANTS)[keyof typeof HOME_PAGE_VARIANTS];

interface IProps {
  variant: THomePageVariants;
  productCategory: TProductCategory;
  charactersData: TCharacter[];
  userFirstName: string;
  retailerLocationName: string;
  isRecipeQuizAvailable: boolean;
  locale: string;
  retailerLanguages: TLanguage[];
  shouldShowLanguageSwitcher: boolean;
  isAnonymousRetailerAccess?: boolean;
  navigateToQuizSelectionPage: () => void;
  navigateToRecipePage: () => void;
  navigateToLoginPage: () => void;
  handleNavigation: () => void;
  handleResetUserData: () => void;
  navigateToCookiePolicyPage: () => void;
}

const HomePage: FC<IProps> = ({
  variant = HOME_PAGE_VARIANTS.ANON,
  productCategory = PRODUCT_CATEGORY_WINE,
  charactersData,
  userFirstName,
  retailerLocationName,
  isRecipeQuizAvailable,
  locale,
  retailerLanguages,
  shouldShowLanguageSwitcher,
  isAnonymousRetailerAccess,
  navigateToQuizSelectionPage,
  navigateToRecipePage,
  navigateToLoginPage,
  handleNavigation,
  handleResetUserData,
  navigateToCookiePolicyPage,
}) => {
  const isAnonVariant = variant === HOME_PAGE_VARIANTS.ANON;
  const isAnonWithCharacterVariant = variant === HOME_PAGE_VARIANTS.ANON_WITH_CHARACTER;
  const isLoggedWithCharacterVariant = variant === HOME_PAGE_VARIANTS.LOGGED_WITH_CHARACTER;
  const isAnonUser = isAnonVariant || isAnonWithCharacterVariant;
  const isAnonAppInFrame = isAnonVariant && isAppInIframe;
  const isUserWithCharacter = isAnonWithCharacterVariant || isLoggedWithCharacterVariant;
  const { productCategoriesPlural } = localeCommon;
  const {
    trainYourTasteAt,
    trainYourTaste,
    authTitle,
    anonTitle,
    tasteChanged,
    alreadyRegistered,
    startTest,
    typeRecipe,
    seeMy,
    deleteYourData,
    saveCharacter,
    checkCookiePolicy,
    userWorldwideText,
  } = localeWidget.homePage;

  const { publishedTerms } = LocaleUtils;
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory]?.id];
  const [isLogoutModalOpened, setIsLogoutModalOpened] = useState(false);

  const preTitleText = userFirstName ? authTitle : anonTitle;
  const titleText = retailerLocationName ? trainYourTasteAt : trainYourTaste;

  const btnText = isUserWithCharacter ? seeMy : startTest;
  const handleBtnClick = isUserWithCharacter ? handleNavigation : navigateToQuizSelectionPage;

  return (
    <S.HomePageContainer>
      {isLogoutModalOpened && (
        <LogoutModal
          handleResetUserData={handleResetUserData}
          hideModal={() => setIsLogoutModalOpened(false)}
          isModalOpen={isLogoutModalOpened}
        />
      )}
      <S.HeaderWrapper
        isFullWidth
        $withTopPadding={!isAnonUser}
        align="center"
        direction="column"
        gap={0}
        justify="center"
      >
        {isAnonUser && shouldShowLanguageSwitcher && (
          <LanguageSwitcher languageSwitcherType="select" locale={locale} retailerLanguages={retailerLanguages} />
        )}
        {!isAnonVariant && (
          <Flexbox justify="center" padding="8px 0">
            <Text color={STATIC_COLORS.base.black} localeOptions={{ userFirstName }} size="body1" text={preTitleText} />
          </Flexbox>
        )}
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          localeOptions={{ retailerLocationName }}
          size="h5"
          text={titleText}
          weight="semibold"
        />
      </S.HeaderWrapper>
      <CharacterScroller charactersData={charactersData} />
      <S.ButtonsWrapper isFullWidth $withTopPadding={!isUserWithCharacter} direction="column" gap={16}>
        {isUserWithCharacter ? (
          <S.LinkButton
            $isBold
            $isCentered
            handleClick={navigateToQuizSelectionPage}
            size="sm"
            text={tasteChanged}
            textWeight="medium"
            variant={VH_VARIANTS.LINK}
          />
        ) : (
          <S.StyledText color={STATIC_COLORS.base.black} size="body2" text={userWorldwideText} textAlign="center" />
        )}
        <Button
          fontSize="subtitle2"
          handleClick={handleBtnClick}
          size="lg"
          text={btnText}
          localeOptions={{
            productCategory,
            productCategoryPluralText,
          }}
        />
        {isRecipeQuizAvailable && (
          <Button
            fontSize="subtitle2"
            handleClick={navigateToRecipePage}
            size="md"
            text={typeRecipe}
            variant={VH_VARIANTS.LIGHT}
          />
        )}
        {isAnonUser && !isAnonymousRetailerAccess && (
          <S.LinkButton
            $isBold
            $isCentered
            handleClick={navigateToLoginPage}
            size="sm"
            text={alreadyRegistered}
            textWeight="normal"
            variant={VH_VARIANTS.LINK}
          />
        )}
      </S.ButtonsWrapper>
      {isAnonWithCharacterVariant && (
        <S.DeleteDataWrapper>
          <S.LinkButton
            $isBold
            fontSize="body2"
            handleClick={() => setIsLogoutModalOpened(true)}
            size="sm"
            text={deleteYourData}
            textWeight="normal"
            variant={VH_VARIANTS.LINK}
          />
        </S.DeleteDataWrapper>
      )}
      {isAnonAppInFrame && (
        <S.CookieWrapper>
          <Text color={STATIC_COLORS.base.black} size="body2" text={saveCharacter} />
          <S.LinkButton
            $isBold
            fontSize="body2"
            handleClick={navigateToCookiePolicyPage}
            size="sm"
            text={checkCookiePolicy}
            textWeight="normal"
            variant={VH_VARIANTS.LINK}
          />
        </S.CookieWrapper>
      )}
    </S.HomePageContainer>
  );
};

export default HomePage;
