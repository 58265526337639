import { Dispatch, FC, SetStateAction } from 'react';

import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { PRODUCT_CARD_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/ProductCard';
import * as S from '@components/web/src/organisms/TestResult/ProductSection/styles';

interface IProps {
  locale: string;
  storeType: TRetailerLocationStoreType;
  productInstanceData: TProductInstance;
  productCategory: TProductCategory;
  retailerLocationName?: string;
  isLoadingProduct: boolean;
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching?: boolean;
  shouldHideComment: boolean;
  shouldHideFeedback: boolean;
  shouldHideWishlist: boolean;
  handleUpdateWishlistProductList?: (productId: string, productName?: string) => void;
  handleProductCardClick?: () => void;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
}

const ProductSection: FC<IProps> = ({
  locale,
  storeType,
  productInstanceData,
  productCategory,
  retailerLocationName,
  isLoadingProduct,
  isCommentsLoaded,
  isNewCommentsDataFetching,
  commentsList,
  shouldHideComment,
  shouldHideFeedback,
  shouldHideWishlist,
  handleUpdateWishlistProductList,
  handleProductCardClick,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
}) => {
  const { productSectionTitle } = localeWidget.testResultPage;

  const { productCategories } = localeCommon;

  return (
    <S.ProductsSection>
      <S.AnimatedWrapper>
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          localeIndex={{ productCategory }}
          localeOptions={{ retailerLocationName }}
          localeVariables={{ productCategoryText: productCategories[productCategory] }}
          size="h5"
          text={productSectionTitle}
          textAlign="center"
          weight="semibold"
        />
      </S.AnimatedWrapper>
      <S.ProductCardWrapper onClick={handleProductCardClick}>
        <ProductCard
          isProductCardClickDisabled
          isResponsive
          commentsList={commentsList}
          handleSubmitComment={handleSubmitComment}
          handleUpdateWishlistProductList={handleUpdateWishlistProductList}
          isCommentsLoaded={isCommentsLoaded}
          isLoading={isLoadingProduct}
          isNewCommentsDataFetching={isNewCommentsDataFetching}
          isProductInstanceInWishlist={false}
          locale={locale}
          mixpanelPositionContext={MP_POSITION_CONTEXT.TEST_RESULT_PAGE}
          productInstanceData={productInstanceData}
          setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
          shouldHideComment={shouldHideComment}
          shouldHideFeedback={shouldHideFeedback}
          shouldHideWishlist={shouldHideWishlist}
          storeType={storeType}
          variant={PRODUCT_CARD_VARIANTS.COMPACT}
        />
      </S.ProductCardWrapper>
    </S.ProductsSection>
  );
};

export default ProductSection;
