import { Dispatch, FC, SetStateAction } from 'react';

import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TJournalProductPanelFilter, TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { TProductFeedback, TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import { ILocation, IUpdateLocationListParams } from '@lib/core/users/types';
import { isLocationInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import FilterContainer from '@lib/tools/filterManager/views/containers/FilterContainer';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  FILTER_TYPE_LOCATION,
  FILTER_TYPE_RATING,
  JOURNAL_PANEL_PLACES_FILTER,
  JOURNAL_PLACES_VARIANT,
  JOURNAL_PRODUCT_VARIANT,
  TJournalPlacesPanelFilter,
  TJournalVariant,
} from '@lib/tools/shared/helpers/consts';

import { JournalProductsList } from '@components/web/src/app/Journal/JournalProductsList';
import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import { PlacesCard } from '@components/web/src/organisms/Cards/PlacesCard/PlacesCard';
import EmptyStateCard from '@components/web/src/organisms/EmptyStateCard/EmptyStateCard';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import { JournalPanel } from '@components/web/src/templates/TasteId/TasteIdJournalPage/JournalPanel';
import * as S from '@components/web/src/templates/TasteId/TasteIdJournalPage/styles';

interface IProps {
  // base props
  variant: TJournalVariant;
  itemsCount: number;
  items: TProductInstance[] | ILocation[];
  productCategory: TProductCategory;
  isItemsLoading: boolean;
  activeJournalPanelFilter: TJournalProductPanelFilter | TJournalPlacesPanelFilter;
  isFilterOpened?: boolean;
  locale: string;
  handlePanelFilterChange?: (value: TJournalProductPanelFilter | TJournalPlacesPanelFilter) => void;
  handleBackBtnClick?: () => void;
  // product variant props
  feedbackData?: TProductFeedback[];
  isAllProductsLoaded?: boolean;
  storeType?: TRetailerLocationStoreType;
  wishlistProductInstanceIds?: string[];
  isWishlistProductListLoading?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  navigateToCatalogPage?: () => void;
  handleProductsRequest?: ({ isPagination }: { isPagination?: boolean }) => void;
  handleUpdateFeedback?: (feedback: TProductFeedbackValue, productId: string, productName?: string) => void;
  handleUpdateWishlistProductList?: (productId: string, productName?: string) => void;
  // places variant props
  isLocationListLoading?: boolean;
  navigateToExplorePage?: () => void;
  handleUpdateLocationList?: ({ retailerLocationSlug }: IUpdateLocationListParams) => void;
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching?: boolean;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
  shouldHideComment?: boolean;
}

const JournalPage: FC<IProps> = ({
  // base props
  variant = JOURNAL_PRODUCT_VARIANT,
  itemsCount,
  items,
  productCategory,
  isItemsLoading,
  locale,
  activeJournalPanelFilter,
  handleBackBtnClick,
  handlePanelFilterChange,
  // product variant props
  feedbackData,
  isFilterOpened = false,
  isAllProductsLoaded = false,
  wishlistProductInstanceIds,
  isWishlistProductListLoading,
  storeType,
  discoveryQuiz,
  navigateToCatalogPage,
  handleProductsRequest,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
  // places variant props
  isLocationListLoading,
  navigateToExplorePage,
  handleUpdateLocationList,
  isCommentsLoaded,
  commentsList,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
  isNewCommentsDataFetching,
  shouldHideComment,
}) => {
  const {
    journalPage: {
      journalPlacesHeaderText,
      journalHeaderText,
      journalRatedText,
      journalSavedText,
      journalVisitedText,
      journalPlacesText,
      journalLocationsText,
    },
    productCategories,
  } = localeCommon;
  const isJournalPanelPlacesFilter = activeJournalPanelFilter === JOURNAL_PANEL_PLACES_FILTER;
  const isJournalPanelFilterTypeRating = activeJournalPanelFilter === FILTER_TYPE_RATING;
  const isVariantLocationLog = activeJournalPanelFilter === FILTER_TYPE_LOCATION;

  const isPlacesVariant = variant === JOURNAL_PLACES_VARIANT;

  const titleText = isPlacesVariant ? journalPlacesHeaderText : journalHeaderText;
  const getCountTitleText = () => {
    if (isPlacesVariant) {
      return isJournalPanelPlacesFilter ? journalSavedText : journalVisitedText;
    }
    return isJournalPanelFilterTypeRating ? journalRatedText : journalSavedText;
  };
  const getCountDescriptionText = () => {
    if (isPlacesVariant) {
      return isJournalPanelPlacesFilter ? journalPlacesText : journalLocationsText;
    }
    return localeCommon.productCategoriesPlural[productCategory];
  };

  return (
    <S.JournalPageContainer>
      <S.JournalPageHeader>
        <BackButton handleClick={handleBackBtnClick} />
        <Text
          color={STATIC_COLORS.base.black}
          localeIndex={{ productCategory }}
          localeVariables={{ productCategoryText: productCategories[productCategory] }}
          size="body1"
          text={titleText}
          weight="bold"
        />
      </S.JournalPageHeader>
      <S.JournalPageContentWrapper $productCategory={productCategory}>
        <JournalPanel
          activeJournalPanelFilter={activeJournalPanelFilter}
          handlePanelFilterChange={handlePanelFilterChange}
          productCategory={productCategory}
          variant={variant}
        />
        <S.JournalPageContent>
          {!isPlacesVariant && (
            <FilterContainer
              isJournalVariant
              isSearchEnabled
              handleApply={handleProductsRequest}
              productCategory={productCategory}
            />
          )}
          <S.JournalCounter $isPlacesVariant={isPlacesVariant}>
            <Text color={STATIC_COLORS.base.black} size="body2" text={getCountTitleText()} weight="semibold" />
            <Text color={STATIC_COLORS.base.black} size="body2" text={`: ${itemsCount || 0}`} weight="semibold" />
            &nbsp;
            <Text
              color={STATIC_COLORS.base.black}
              size="body2"
              text={getCountDescriptionText()}
              textTransform="lowercase"
              weight="semibold"
            />
          </S.JournalCounter>
          {!isFilterOpened && !isPlacesVariant && (
            <JournalProductsList
              activeJournalPanelFilter={activeJournalPanelFilter}
              commentsList={commentsList}
              discoveryQuiz={discoveryQuiz}
              feedbackData={feedbackData}
              handleProductsRequest={handleProductsRequest}
              handleSubmitComment={handleSubmitComment}
              handleUpdateFeedback={handleUpdateFeedback}
              handleUpdateWishlistProductList={handleUpdateWishlistProductList}
              isAllProductsLoaded={isAllProductsLoaded}
              isCommentsLoaded={isCommentsLoaded}
              isNewCommentsDataFetching={isNewCommentsDataFetching}
              isProductsRequestLoading={isItemsLoading}
              isWishlistProductListLoading={isWishlistProductListLoading}
              itemsCount={itemsCount}
              locale={locale}
              navigateToCatalogPage={navigateToCatalogPage}
              productCategory={productCategory}
              products={items as TProductInstance[]}
              setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
              shouldHideComment={shouldHideComment}
              storeType={storeType}
              wishlistProductInstanceIds={wishlistProductInstanceIds}
            />
          )}
          {isPlacesVariant && (
            <S.JournalPlacesList>
              <div className="scroll-wrapper">
                <div className="scroller">
                  {items?.length ? (
                    (items as ILocation[]).map((data, idx) => (
                      <PlacesCard
                        key={idx}
                        isResponsive
                        handleUpdateLocationList={handleUpdateLocationList}
                        isLocationListLoading={isLocationListLoading}
                        isVariantLocationLog={isVariantLocationLog}
                        locationData={data}
                        locationPositionContext={MP_POSITION_CONTEXT.SCROLLABLE_CATALOG}
                        locationPositionIndex={idx}
                        productCategory={productCategory}
                        isLocationInWishlist={isLocationInWishlistFilter(
                          items as ILocation[],
                          data?.retailer_location?.identifier,
                        )}
                      />
                    ))
                  ) : (
                    <S.NoProductsWrapper>
                      <EmptyStateCard
                        handleBtnClick={navigateToExplorePage}
                        productCategory={productCategory}
                        variant="places"
                      />
                    </S.NoProductsWrapper>
                  )}
                </div>
              </div>
            </S.JournalPlacesList>
          )}
        </S.JournalPageContent>
      </S.JournalPageContentWrapper>
    </S.JournalPageContainer>
  );
};

export default JournalPage;
