import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRetailer } from '@lib/core/retailers/hooks';
import { B2C_MODALS } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { setServiceLocale } from '@lib/core/service/slices';
import { useDietaryPreferences, useUser } from '@lib/core/users/hooks';
import { actionUpdateProfileData } from '@lib/core/users/slices/profile';
import { actionGetUserData, actionUpdateUserData, actionUploadUserPicture } from '@lib/core/users/slices/user';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { useModals } from '@lib/tools/modals/hooks';
import { resetProductSwiperState } from '@lib/tools/productsSwiper/slices/index';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import FoodPreferencesModal from '@components/web/src/templates/Modals/FoodPreferencesModal/FoodPreferencesModal';
import ManageModal from '@components/web/src/templates/Modals/ManageModal/ManageModal';
import SettingsPage from '@components/web/src/templates/TasteId/TasteIdSettingsPage/TasteIdSettingsPage';
import { IFormData } from '@components/web/src/templates/TasteId/TasteIdSettingsPage/UserInfoForm';

const TasteIdSettingsPageContainer: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const { closeAllModals, openModal, isFoodPreferencesModalOpened, isManageModalOpened } = useModals();
  const { locale } = useApp();
  const { retailerLanguages } = useRetailer();
  const {
    handleResetUserData,
    isUploadingProfilePicture,
    userFirstName,
    userLastName,
    userEmail,
    userImage,
    profileCountry,
    profileGenderSlug,
    profileBirthYear,
    profileCountryOptions,
    profileGenderOptions,
  } = useUser();
  const { togglePreferenceOptionsData, handleUpdateToggleDietaryPreferenceOptionsData } = useDietaryPreferences();

  const formData: IFormData = {
    birthYear: profileBirthYear?.toString() ?? '',
    country: profileCountry ?? '',
    email: userEmail ?? '',
    firstName: userFirstName ?? '',
    gender: profileGenderSlug ?? '',
    lastName: userLastName ?? '',
  };

  const { deactivateAccountTitle, deactivateAccountDesc, deleteAccountTitle, deleteAccountDesc } =
    localeApp.b2cModalsHoc;

  const accountOptions = {
    deactivateaccount: {
      category: 'deactivateaccount',
      is_always_active: false,
      is_selected: false,
      text: deactivateAccountDesc,
      title: deactivateAccountTitle,
    },
    deleteaccount: {
      category: 'deleteaccount',
      is_always_active: false,
      is_selected: false,
      text: deleteAccountDesc,
      title: deleteAccountTitle,
    },
  };

  const [userImageUpdateError, setUserImageUpdateError] = useState('');

  const handleUserImageUpdate = (base64ImageData: string) =>
    dispatch(actionUploadUserPicture(base64ImageData)).then(() => {
      dispatch(actionGetUserData());
    });

  const handleFormSubmit = (data: IFormData) => {
    dispatch(
      actionUpdateProfileData({
        birth_year: data.birthYear && Number(data.birthYear),
        country: data.country,
        gender: data.gender,
      }),
    );

    dispatch(
      actionUpdateUserData({
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
      }),
    );
  };

  const handleLanguageChange = (language: string) =>
    dispatch(actionUpdateProfileData({ language })).then(() => {
      dispatch(setServiceLocale(language));
      dispatch(resetProductSwiperState());
    });

  const handleLogout = () => handleResetUserData();

  const handleBackBtnClick = () => navigate(-1);

  return (
    <>
      <SettingsPage
        currentLanguage={locale}
        formData={formData}
        handleBackBtnClick={handleBackBtnClick}
        handleFormSubmit={handleFormSubmit}
        handleLanguageChange={handleLanguageChange}
        handleUpdateToggleDietaryPreferenceOptionsData={handleUpdateToggleDietaryPreferenceOptionsData}
        handleUserImageUpdate={handleUserImageUpdate}
        handleUserImageUpdateError={setUserImageUpdateError}
        profileCountryOptions={profileCountryOptions}
        profileGenderOptions={profileGenderOptions}
        supportedLanguages={retailerLanguages}
        togglePreferenceOptionsData={togglePreferenceOptionsData}
        userImage={userImage}
        userImageUpdateError={userImageUpdateError}
        onExitBtnClick={handleLogout}
        onManageBtnClick={() => openModal(B2C_MODALS.MANAGE_MODAL)}
        onPreferencesHintBtnClick={() => openModal(B2C_MODALS.FOOD_PREFERENCES_MODAL)}
      />

      {isFoodPreferencesModalOpened && (
        <FoodPreferencesModal hideModal={closeAllModals} isModalOpen={isFoodPreferencesModalOpened} />
      )}

      {isManageModalOpened && (
        <ManageModal
          accountOptions={accountOptions}
          handleCancelButton={closeAllModals}
          handleConfirmButton={values => console.info(values)}
          hideModal={closeAllModals}
          isModalOpen={isManageModalOpened}
        />
      )}

      {isUploadingProfilePicture && <LoadingSpinner />}
    </>
  );
};

export default TasteIdSettingsPageContainer;
