import styled from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const SituationalCardContainer = styled.div<{ $productCategory: string }>`
  width: 132px;
  height: 180px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: ${STATIC_SHADOWS.lg};
  background-color: ${({ $productCategory }) => STATIC_COLORS.productColors[$productCategory][500]};
`;

export const ImageContainer = styled.div`
  min-height: 124px;
  max-height: 124px;
  border-radius: 8px 8px 0 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 8px 10px;

  svg {
    margin-left: auto;
  }
`;
