import { Dispatch, FC, SetStateAction, useState } from 'react';

import { IB2CExperience } from '@app/native/src/interfaces/experience';

import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { TProductFeedback, TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import { IUpdateLocationListParams } from '@lib/core/users/types';
import { feedbackFilter, isProductInstanceInWishlistFilter } from '@lib/core/users/utils/filters';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PRODUCT_CARD_VARIANTS } from '@lib/tools/shared/helpers/consts';
import { parseTasteMatchLevel } from '@lib/tools/tasteMatch';

import addressIcon from '@components/web/src/assets/icons/explore/address_icon.svg';
import mailIcon from '@components/web/src/assets/icons/explore/mail_icon.svg';
import phoneIcon from '@components/web/src/assets/icons/explore/phone_icon.svg';
import siteIcon from '@components/web/src/assets/icons/explore/site_icon.svg';
import TasteMatchButton from '@components/web/src/atoms/TasteMatchButton/TasteMatchButton';
import TooltipComponent from '@components/web/src/atoms/Tooltips/Tooltip';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import ExperienceCard from '@components/web/src/organisms/Cards/ExperienceCard/ExperienceCard';
import { IMapLocation, MapCard } from '@components/web/src/organisms/Cards/MapCard/MapCard';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/ProductCard';
import * as S from '@components/web/src/templates/ExplorePage/styles';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  retailerLocationAddress: string;
  retailerLocationImages: string[];
  retailerLocationId: string;
  retailerLocationPhone: string;
  retailerLocationWebsite: string;
  retailerLocationEmail: string;
  retailerLocationName: string;
  retailerLocationLogo: string;
  retailerLocationDescription: string;
  retailerLocationSlug: string;
  retailerLocationTasteMatchValue: number | null;
  productCategory: string;
  productInstanceData: TProductInstance;
  locale: string;
  storeType: TRetailerLocationStoreType;
  isUserHasAnyCharacter: boolean;
  isUserAuthenticated: boolean;
  wishlistProductInstanceIds: string[];
  feedbackData: TProductFeedback[];
  discoveryQuiz: IDiscoveryQuiz;
  experienceData: IB2CExperience[];
  isLocationInWishlist: boolean;
  isLocationListLoading: boolean;
  isWishlistProductListLoading: boolean;
  isCompactVariant: boolean;
  shouldHideTasteMatchButton?: boolean;
  mapLocationList: IMapLocation[];
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching?: boolean;
  navigateToCatalogPage: () => void;
  navigateToQuizSelectionPage: () => void;
  navigateToExploreListPage: () => void;
  handleUpdateWishlistProductList: (productId: string, productName?: string) => void;
  handleUpdateLocationList: ({ retailerLocationSlug }: IUpdateLocationListParams) => void;
  handleUpdateFeedback: (feedback: TProductFeedbackValue, productId: string, productName?: string) => void;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
}

export const ExplorePage: FC<IProps> = ({
  retailerLocationAddress = '',
  retailerLocationImages = [],
  retailerLocationPhone = '',
  retailerLocationWebsite = '',
  retailerLocationEmail = '',
  retailerLocationName = '',
  retailerLocationLogo = '',
  retailerLocationDescription = '',
  retailerLocationId,
  retailerLocationSlug,
  retailerLocationTasteMatchValue,
  productCategory,
  productInstanceData,
  locale,
  storeType,
  isUserHasAnyCharacter,
  wishlistProductInstanceIds = [],
  feedbackData = [],
  discoveryQuiz,
  experienceData = [],
  isLocationInWishlist,
  isLocationListLoading,
  isWishlistProductListLoading,
  isCompactVariant,
  shouldHideTasteMatchButton = true,
  mapLocationList,
  isCommentsLoaded,
  commentsList,
  isNewCommentsDataFetching,
  navigateToCatalogPage,
  navigateToExploreListPage,
  navigateToQuizSelectionPage,
  handleUpdateWishlistProductList,
  handleUpdateLocationList,
  handleUpdateFeedback,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
}) => {
  const { titleText, experiencesText, listTitle, listSubtitle, listBtn } = localeWidget.explorePage;

  const { productCategoriesPlural, productCategories, productDetails } = localeCommon;
  const { publishedTerms } = LocaleUtils;
  const productCategoryText = localeCommon.productCategories[productCategory];
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory].id];

  const [isTooltipVisible, setTooltipVisible] = useState(true);

  const contactData = [
    retailerLocationWebsite && {
      href: retailerLocationWebsite,
      icon: siteIcon,
      isSiteContact: true,
      text: retailerLocationWebsite,
    },
    retailerLocationPhone && {
      href: `tel:${retailerLocationPhone}`,
      icon: phoneIcon,
      text: retailerLocationPhone,
    },
    retailerLocationEmail && {
      href: `mailto:${retailerLocationEmail}`,
      icon: mailIcon,
      text: retailerLocationEmail,
    },
    retailerLocationAddress && {
      href: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(retailerLocationAddress)}`,
      icon: addressIcon,
      isAddressContact: true,
      text: retailerLocationAddress,
    },
  ].filter(Boolean);

  return (
    <S.ExplorePageContainer>
      {!!mapLocationList?.length && (
        <MapCard mapHeight={144} mapLocationList={mapLocationList} productCategory={productCategory} />
      )}
      <S.ContentWrapper>
        <S.InfoSection>
          <Flexbox align="center" justify="space-between">
            {retailerLocationLogo && <img alt="retailer logo" src={retailerLocationLogo} />}
            <Wishlist
              isItemInWishlist={isLocationInWishlist}
              isItemListLoading={isLocationListLoading}
              handleUpdateWishlist={() => {
                MixpanelTracker.events.locationBookmark({
                  listName: '',
                  locationBookmarked: isLocationInWishlist,
                  locationId: retailerLocationId,
                  locationName: retailerLocationName,
                  locationPosition: MP_POSITION_CONTEXT.EXPLORE_PAGE,
                  locationPositionIndex: null,
                  locationSlug: retailerLocationSlug,
                });

                handleUpdateLocationList({ retailerLocationSlug });
              }}
            />
          </Flexbox>

          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            size="h5"
            text={retailerLocationName}
            weight="semibold"
          />
          {retailerLocationDescription && (
            <Text color={STATIC_COLORS.base.black} size="body1" text={retailerLocationDescription} />
          )}
        </S.InfoSection>
        {productInstanceData && !shouldHideTasteMatchButton && (
          <TooltipComponent
            isAlwaysOpen={isTooltipVisible}
            placement="top-end"
            title={productDetails.tasteMatchTooltip}
            trigger={
              <TasteMatchButton
                isLocationTasteMatchEvent
                hideTooltip={() => setTooltipVisible(false)}
                navigateToQuizSelectionPage={navigateToQuizSelectionPage}
                retailerLocationId={retailerLocationId}
                retailerLocationName={retailerLocationName}
                retailerLocationSlug={retailerLocationSlug}
                tasteMatchLevel={parseTasteMatchLevel(retailerLocationTasteMatchValue, isUserHasAnyCharacter)}
                tasteMatchValue={retailerLocationTasteMatchValue}
                variant="catalog"
              />
            }
          />
        )}
        <S.ContactSection>
          {retailerLocationImages && retailerLocationImages.length > 0 && (
            <SwiperHOC slidesPerView={1}>
              {retailerLocationImages.map((image, idx) => (
                <S.LocationImage key={idx} $image={image} />
              ))}
            </SwiperHOC>
          )}
          <Flexbox direction="column" gap={8}>
            {contactData.map(({ icon, text, isSiteContact = false, isAddressContact = false, href }, idx) => (
              <S.Contact key={idx} $isAddressContact={isAddressContact}>
                <img alt="contact link" src={icon} />
                <a href={href} {...(isSiteContact && { target: '_blank' })}>
                  <Text color={STATIC_COLORS.base.black} size="body2" text={text} />
                </a>
              </S.Contact>
            ))}
          </Flexbox>
        </S.ContactSection>
        {!isCompactVariant && (
          <>
            {productInstanceData && (
              <S.ProductSection>
                <Text
                  color={STATIC_COLORS.base.black}
                  fontFamily="Fraunces"
                  localeIndex={{ productCategory }}
                  localeVariables={{ productCategoryText: productCategories[productCategory] }}
                  size="h5"
                  text={titleText}
                  weight="semibold"
                />
                <ProductCard
                  isResponsive
                  commentsList={commentsList}
                  discoveryQuiz={discoveryQuiz}
                  feedback={feedbackFilter(feedbackData, productInstanceData)}
                  handleSubmitComment={handleSubmitComment}
                  handleUpdateFeedback={handleUpdateFeedback}
                  handleUpdateWishlistProductList={handleUpdateWishlistProductList}
                  isCommentsLoaded={isCommentsLoaded}
                  isLoading={false}
                  isNewCommentsDataFetching={isNewCommentsDataFetching}
                  isWishlistProductListLoading={isWishlistProductListLoading}
                  locale={locale}
                  mixpanelIndex={0}
                  mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
                  productInstanceData={productInstanceData}
                  setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
                  storeType={storeType}
                  variant={PRODUCT_CARD_VARIANTS.COMPACT}
                  isProductInstanceInWishlist={isProductInstanceInWishlistFilter(
                    wishlistProductInstanceIds,
                    productInstanceData,
                  )}
                />
              </S.ProductSection>
            )}
            <S.Button
              size="md"
              text={`${retailerLocationName} ${productCategoryPluralText}`}
              handleClick={() => {
                MixpanelTracker.events.seeAllProducts();
                navigateToCatalogPage();
              }}
              localeOptions={{
                productCategory,
                productCategoryPluralText,
                retailerName: retailerLocationName,
              }}
            />
          </>
        )}
      </S.ContentWrapper>
      {!isCompactVariant && (
        <>
          {!!experienceData.length && (
            <S.ExperienceSection $isSingleCard={experienceData.length === 1}>
              <Text
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={experiencesText}
                weight="semibold"
              />
              {experienceData.length === 1 ? (
                <ExperienceCard key="1" isResponsive experienceData={experienceData[0]} isVisibleBadges={false} />
              ) : (
                <SwiperHOC spaceBetween={20}>
                  {experienceData.map((experience, idx) => (
                    <ExperienceCard key={idx} experienceData={experience} isVisibleBadges={false} />
                  ))}
                </SwiperHOC>
              )}
            </S.ExperienceSection>
          )}
          <S.CtaWrapper>
            <Flexbox direction="column" gap={0}>
              <Text
                color={STATIC_COLORS.productColors[productCategory][500]}
                size="subtitle2"
                text={listTitle}
                weight="medium"
              />
              <Text
                color={STATIC_COLORS.productColors[productCategory][500]}
                fontFamily="Fraunces"
                localeIndex={{ productCategory }}
                localeVariables={{ productCategoryText }}
                size="h4"
                text={listSubtitle}
                textTransform="capitalize"
                weight="semibold"
              />
            </Flexbox>
            <S.Button
              handleClick={() => navigateToExploreListPage()}
              size="md"
              text={listBtn}
              localeOptions={{
                retailerName: retailerLocationName,
              }}
            />
          </S.CtaWrapper>
        </>
      )}
    </S.ExplorePageContainer>
  );
};
