import styled from 'styled-components';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const AuthenticationPageContainer = styled(Flexbox)`
  background-color: ${STATIC_COLORS.base.white};
  flex: 1;
`;

export const LockIcon = styled.img`
  width: 22px;
  height: 27.4px;
`;

export const CharacterIcon = styled.div`
  position: relative;
  height: 220px;
  margin: 0 auto;

  img {
    position: relative;
    z-index: 1;
    height: 100%;
  }
`;

export const CharacterBackground = styled.div<{ $backgroundColor?: string }>`
  position: absolute;
  box-sizing: content-box;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  width: 172px;
  height: 172px;
  border-radius: 50%;
  border: 5px solid ${STATIC_COLORS.base.white};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const ContentWrapper = styled(Flexbox)`
  position: relative;
  background-color: ${STATIC_COLORS.warmGray[100]};
  flex: 1;

  p {
    margin: 0;
  }
`;

export const Circle = styled.div<{
  $width?: number;
  $top?: number;
  $sideOffset?: number;
  $side?: 'left' | 'right';
  $color?: string;
  $zIndex?: number;
}>`
  position: absolute;
  width: ${({ $width }) => `${$width || 300}px`};
  height: 200px;
  top: ${({ $top }) => `${$top || -50}px`};
  ${({ $side = 'left', $sideOffset }) =>
    $side === 'left' ? `left: ${$sideOffset || -60}px;` : `right: ${$sideOffset || -60}px;`};
  background-color: ${({ $color }) => $color || STATIC_COLORS.warmGray[100]};
  border-radius: 50%;
  z-index: ${({ $zIndex }) => $zIndex || 0};
`;

export const Content = styled(Flexbox)`
  position: relative;
  z-index: 2;
`;

export const LinkButton = styled(Button)`
  padding: 18px 14px 26px;
  justify-content: center;

  span {
    color: ${STATIC_COLORS.base.black};
    text-decoration: underline;
  }
`;
