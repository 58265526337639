import { Dispatch, FC, SetStateAction } from 'react';

import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { TProductFeedback, TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import { feedbackFilter, isProductInstanceInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import {
  LIMITED_CATALOG,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/ProductCard';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import { LimitedFilter } from '@components/web/src/widget/Catalog/LimitedFilter/LimitedFilter';
import * as S from '@components/web/src/widget/Catalog/styles';

interface IProps {
  matchedProducts: TProductInstance[];
  catalogType: string;
  isProductsRequestLoading: boolean;
  productCategory: TProductCategory;
  storeType: TRetailerLocationStoreType;
  locale: string;
  isEnableLocationMapAddon?: boolean;
  isEnableVusionAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  feedbackData?: TProductFeedback[];
  wishlistProductInstanceIds: string[];
  isWishlistProductListLoading?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  shouldHideFeedback: boolean;
  shouldHideComment: boolean;
  shouldHideWishlist: boolean;
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching?: boolean;
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  handleUpdateWishlistProductList?: (productId: string) => void;
  handleUpdateFeedback?: (feedback: TProductFeedbackValue, productId: string, productName?: string) => void;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
}

const MatchedProducts: FC<IProps> = ({
  isProductsRequestLoading,
  matchedProducts,
  productCategory = PRODUCT_CATEGORY_COFFEE,
  catalogType,
  storeType,
  locale,
  isEnableLocationMapAddon,
  isEnableVusionAddon,
  isShowProductLocationAddon,
  wishlistProductInstanceIds = [],
  isWishlistProductListLoading,
  feedbackData,
  discoveryQuiz,
  shouldHideFeedback,
  shouldHideComment,
  shouldHideWishlist,
  isCommentsLoaded,
  isNewCommentsDataFetching,
  commentsList,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
  handleProductsRequest,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
}) => {
  const { productCatalog } = localeWidget;

  const swiperHeadingTextByProduct = {
    [PRODUCT_CATEGORY_BEER]: productCatalog.pourBeer,
    [PRODUCT_CATEGORY_COFFEE]: productCatalog.pourCoffee,
    [PRODUCT_CATEGORY_WINE]: productCatalog.uncorkTheWineShort,
  };

  const isLimitedCatalog = catalogType === LIMITED_CATALOG;
  const isSingleProduct = !isProductsRequestLoading && matchedProducts.length === 1;

  const productView = isSingleProduct ? (
    <ProductCard
      isResponsive
      commentsList={commentsList}
      discoveryQuiz={discoveryQuiz}
      feedback={feedbackFilter(feedbackData, matchedProducts[0])}
      handleSubmitComment={handleSubmitComment}
      handleUpdateFeedback={handleUpdateFeedback}
      handleUpdateWishlistProductList={handleUpdateWishlistProductList}
      isCommentsLoaded={isCommentsLoaded}
      isEnableLocationMapAddon={isEnableLocationMapAddon}
      isEnableVusionAddon={isEnableVusionAddon}
      isLoading={false}
      isNewCommentsDataFetching={isNewCommentsDataFetching}
      isProductInstanceInWishlist={isProductInstanceInWishlistFilter(wishlistProductInstanceIds, matchedProducts[0])}
      isShowProductLocationAddon={isShowProductLocationAddon}
      isWishlistProductListLoading={isWishlistProductListLoading}
      locale={locale}
      mixpanelIndex={0}
      mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
      productInstanceData={matchedProducts[0]}
      setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
      shouldHideComment={shouldHideComment}
      shouldHideFeedback={shouldHideFeedback}
      shouldHideWishlist={shouldHideWishlist}
      storeType={storeType}
    />
  ) : (
    <SwiperHOC>
      {matchedProducts.map((productInstanceData, index) => (
        <ProductCard
          key={index}
          commentsList={commentsList}
          discoveryQuiz={discoveryQuiz}
          feedback={feedbackFilter(feedbackData, productInstanceData)}
          handleSubmitComment={handleSubmitComment}
          handleUpdateFeedback={handleUpdateFeedback}
          handleUpdateWishlistProductList={handleUpdateWishlistProductList}
          isCommentsLoaded={isCommentsLoaded}
          isEnableLocationMapAddon={isEnableLocationMapAddon}
          isEnableVusionAddon={isEnableVusionAddon}
          isLoading={false}
          isNewCommentsDataFetching={isNewCommentsDataFetching}
          isShowProductLocationAddon={isShowProductLocationAddon}
          isWishlistProductListLoading={isWishlistProductListLoading}
          locale={locale}
          mixpanelIndex={index}
          mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
          productInstanceData={productInstanceData}
          setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
          shouldHideComment={shouldHideComment}
          shouldHideFeedback={shouldHideFeedback}
          shouldHideWishlist={shouldHideWishlist}
          storeType={storeType}
          isProductInstanceInWishlist={isProductInstanceInWishlistFilter(
            wishlistProductInstanceIds,
            productInstanceData,
          )}
        />
      ))}
    </SwiperHOC>
  );

  return (
    <S.SwiperSection $isSingleProduct={isSingleProduct}>
      <S.SwiperTitleSection>
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          size="h5"
          text={swiperHeadingTextByProduct[productCategory]}
          weight="semibold"
        />
        {isLimitedCatalog && false && <LimitedFilter handleProductsRequest={handleProductsRequest} />}
      </S.SwiperTitleSection>

      {isProductsRequestLoading ? (
        <SwiperHOC>
          {[1, 2, 3].map(key => (
            <ProductCard key={key} isLoading={isProductsRequestLoading} locale={locale} storeType={storeType} />
          ))}
        </SwiperHOC>
      ) : (
        productView
      )}
    </S.SwiperSection>
  );
};

export default MatchedProducts;
