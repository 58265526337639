import styled from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const WebProductPageContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: var(--color-secondary-100);
`;

export const StyledBackButton = styled(BackButton)`
  padding: 8px 16px;
`;

export const Title = styled(Text)`
  margin-bottom: 4px;
`;

export const Subtitle = styled(Text)`
  margin-bottom: 16px;

  br {
    display: none;
  }
`;

export const ContentWrapper = styled(Flexbox)`
  .swiper-pagination {
    display: block;
    max-width: 500px;
  }
`;

export const PerfectForWrapper = styled.div`
  padding: 8px 16px;
  margin-top: 12px;
  background: ${STATIC_COLORS.base.white};
  border-radius: 8px;
`;

export const PreparationsWrapper = styled.div`
  margin-bottom: -28px;
`;
