import styled from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';

export const ExploreListPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary-100);
`;

export const InputWrapper = styled.div`
  padding: 20px 16px;
`;

export const ContentWrapper = styled.div<{ $withExtraMargin: boolean }>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
  padding: 24px 16px;
  border-radius: 10px 10px 0px 0px;
  background: ${STATIC_COLORS.base.white};
  margin-top: ${({ $withExtraMargin }) => ($withExtraMargin ? '-10px' : '0')};
`;
