import { FC } from 'react';

import { TProductInstance } from '@lib/core/products/types';
import { TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import Tabs from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import ProductDetails from '@components/web/src/organisms/ProductDetails/Web/ProductDetails';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

type Props = {
  locale: string;
  tabsProps: { active: string; tabs: any[] };
  productInstanceData: TProductInstance;
  isShowPromoLabel?: boolean;
  feedback?: TProductFeedbackValue;
  isWishlistProductListLoading?: boolean;
  isProductInstanceInWishlist?: boolean;
  isUserHasAnyCharacter: boolean;
  shouldDisplayContactProducerBtn?: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  shouldHideWishlist: boolean;
  shouldHideComment: boolean;
  shouldHideFeedback: boolean;
  navigateToQuizSelectionPage: () => void;
  onBackButtonClick: () => void;
  handleUpdateWishlistProductList?: (productId: string, productName?: string) => void;
  handleUpdateFeedback?: (feedback: TProductFeedbackValue, productId: string, productName?: string) => void;
};

const AppProductPage: FC<Props> = ({
  productInstanceData,
  locale,
  tabsProps,
  isShowPromoLabel = false,
  feedback,
  isProductInstanceInWishlist = false,
  isWishlistProductListLoading = false,
  shouldDisplayContactProducerBtn,
  discoveryQuiz,
  isUserHasAnyCharacter,
  shouldHideComment,
  shouldHideFeedback,
  shouldHideWishlist,
  onBackButtonClick,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
  navigateToQuizSelectionPage,
}) => {
  return (
    <div className="app-product-page-container">
      <BackButton handleClick={onBackButtonClick} />
      <ProductDetails
        discoveryQuiz={discoveryQuiz}
        feedback={feedback}
        handleUpdateFeedback={handleUpdateFeedback}
        handleUpdateWishlistProductList={handleUpdateWishlistProductList}
        isProductInstanceInWishlist={isProductInstanceInWishlist}
        isShowPromoLabel={isShowPromoLabel}
        isUserHasAnyCharacter={isUserHasAnyCharacter}
        isWishlistProductListLoading={isWishlistProductListLoading}
        locale={locale}
        navigateToQuizSelectionPage={navigateToQuizSelectionPage}
        productInstanceData={productInstanceData}
        shouldDisplayContactProducerBtn={shouldDisplayContactProducerBtn}
        shouldHideComment={shouldHideComment}
        shouldHideFeedback={shouldHideFeedback}
        shouldHideWishlist={shouldHideWishlist}
      />
      <Tabs {...tabsProps} gradientVariant="default-down" />
    </div>
  );
};

export default AppProductPage;
